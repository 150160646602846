import { switchLoading } from '../../methods/global/loading'

export default {
  namespaced: true,
  state: {
    p_loading: false,
    p_bill: {},
    p_commonStyles: {},
    p_merchandise: {},
    p_merchOrder: {},
    p_participant: {},
    p_purchaseOrder: {},
    p_shipper: {},
    p_shop: {},
    p_stock: {},
    p_store: {},
    p_fb: {},
    p_customerMerchorder: {},
    p_line: {},
  },
  mutations: {
    // loading 開關
    SWITCHLOADING(state, payload) {
      state.p_loading = payload
    },
    // 紀錄偏好
    RECORDDATA(state, payload) {
      if (state[`p_${payload.category}`]) state[`p_${payload.category}`][payload.item] = payload.data
      else console.log(new Error(`vuex > prefer > 少了 category: ${payload.category}, item: ${payload.item}`))
      console.log('紀錄偏好中...')
    },
  },
  actions: {
    // 取得偏好 (做同步)
    getStoreInfo(context, payload) {
      switchLoading('show')
      context.commit('SWITCHLOADING', true)
      const getStoreInfoApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: payload.serverToken
      }
      const data = [
        {
          type: 5,
          ids: [payload.storeId],
          methods: '{getConfiguration}'
        }
      ]
      $.ajax({
        type: 'POST',
        async: false,
        url: getStoreInfoApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            console.log('已取得偏好')
            const storeInfo = res.data[0].objects[0]
            context.dispatch('sortoutConfiguration', storeInfo.configuration)
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          context.commit('SWITCHLOADING', false)
          alert(err.responseJSON.message)
          setTimeout(() => {
            switchLoading('hide')
          }, 500)
        },
      })
    },
    // 整理偏好
    sortoutConfiguration(context, configurations) {
      configurations.forEach(item => {
        context.commit('RECORDDATA', {
          category: item.category,
          item: item.item,
          data: item.settingValues
        })
      })
      context.commit('SWITCHLOADING', false)
      setTimeout(() => {
        switchLoading('hide')
      }, 500)
    },
    // 更新偏好
    /**
     * payload: {
     *  storeId,
     *  serverToken.
     *  category (偏好的 category)
     *  item (偏好的 item)
     *  data (settingValues 內的 item)
     * } 
     */
    updateConfiguration(context, payload) {
      switchLoading('show')
      const updateConfigurationApi = `${process.env.VUE_APP_API}/storeConfiguration/updateSettingValue`
      const header = {
        authorization: payload.serverToken
      }
      let data = []
      console.log(payload)
      switch (payload.item) {
        case 'row':
          context.state[payload.category].rowCount.forEach(row => {
            if (row.id === payload.data.id) {
              data.push({
                id: row.id,
                value: row.value,
                selected: true
              })
            } else {
              data.push({
                id: row.id,
                value: row.value,
                selected: false
              })
            }
          })
          break;
        case 'timeRange':
          if (!payload.data || (payload.category !== 'p_stock' && payload.data > 90) || (payload.category === 'p_stock' && payload.data > 180)) {
            switchLoading('hide')
            context.dispatch('getStoreInfo', {
              storeId: payload.storeId,
              serverToken: payload.serverToken
            })
            if (payload.category !== 'p_stock') return alert('時間區間必須大於0小於等於90')
            else return alert('時間區間必須大於0小於等於180')
          } else {
            data.push({
              id: context.state[payload.category].searchTimeRange[0].id,
              value: payload.data,
              selected: true
            })
          }
          break;
        case 'd4DataTable':
          context.state[payload.category].d4DataTable.forEach(d4 => {
            if (d4.id === payload.data.id) {
              data.push({
                id: d4.id,
                value: d4.value,
                selected: true
              })
            } else {
              data.push({
                id: d4.id,
                value: d4.value,
                selected: false
              })
            }
          })
          break;
        case 'shipperSetting':
          data.push({
            id: payload.data.id,
            value: payload.data.value,
            selected: !payload.data.selected
          })
          break;
        case 'merchCard':
          context.state[payload.category].merchCard.forEach(item => {
            if (item.value === payload.data) {
              data.push({
                id: item.id,
                value: item.value,
                selected: true
              })
            } else {
              data.push({
                id: item.id,
                value: item.value,
                selected: false
              })
            }
          })
          break;
        case 'orderPage':
          context.state[payload.category].orderPage.forEach(item => {
            if (item.value === payload.data) {
              data.push({
                id: item.id,
                value: item.value,
                selected: true
              })
            } else {
              data.push({
                id: item.id,
                value: item.value,
                selected: false
              })
            }
          })
          break;
        case 'closeShopCanStillOrder':
          context.state[payload.category].closeShopCanStillOrder.forEach(item => {
            data.push({
              id: item.id,
              value: item.value,
              selected: payload.data
            })
          })
          break;
        case 'showArrivalTime':
          context.state[payload.category].showArrivalTime.forEach(item => {
            data.push({
              id: item.id,
              value: item.value,
              selected: payload.data
            })
          })
          break;
        case 'showStock':
          context.state[payload.category].showStock.forEach(item => {
            data.push({
              id: item.id,
              value: item.value,
              selected: payload.data
            })
          })
          break;
        case 'sellQuantity':
          context.state[payload.category].sellQuantity.forEach(item => {
            if (item.value === payload.data) {
              data.push({
                id: item.id,
                value: item.value,
                selected: true
              })
            } else {
              data.push({
                id: item.id,
                value: item.value,
                selected: false
              })
            }
          })
          break;
        case 'cloneMerchToStore':
          context.state[payload.category].cloneMerchToStore.forEach(item => {
            data.push({
              id: item.id,
              value: item.value,
              selected: payload.data
            })
          })
          break;
        case 'showDiscounts':
          context.state[payload.category].showDiscounts.forEach(item => {
            if (item.value === payload.data) {
              data.push({
                id: item.id,
                value: item.value,
                selected: true
              })
            } else {
              data.push({
                id: item.id,
                value: item.value,
                selected: false
              })
            }
          })
          break;
        case 'customerCanEditCheckoutQuantity':
          context.state[payload.category].customerCanEditCheckoutQuantity.forEach(item => {
            data.push({
              id: item.id,
              value: item.value,
              selected: payload.data
            })
          })
          break;
        case 'showDetail':
          context.state[payload.category].showDetail.forEach(item => {
            data.push({
              id: item.id,
              value: item.value,
              selected: payload.data
            })
          })
          break;
        case 'customerCheckoutCanSetReceiptInfo':
          context.state[payload.category].customerCheckoutCanSetReceiptInfo.forEach(item => {
            data.push({
              id: item.id,
              value: item.value,
              selected: payload.data
            })
          })
          break;
        case 'fieldFilter':
          context.dispatch('fieldFilterFn', payload, 'fieldFilter').then(res => {
            console.log(res)
            data = res
          })
          break;
        case 'fieldFilterB':
          context.dispatch('fieldFilterFn', payload, 'fieldFilterB').then(res => {
            console.log(res)
            data = res
          })
          break;
        case 'linePushD4Text':
          data.push({
            id: context.state[payload.category].linePushD4Text[0].id,
            value: payload.data.value,
            selected: null
          })
          break;
        case 'placeOnOrderUrlPosition':
          context.state[payload.category].placeOnOrderUrlPosition.forEach(item => {
            if (item.id === payload.data.id) {
              data.push({
                id: item.id,
                value: item.value,
                selected: true
              })
            } else {
              data.push({
                id: item.id,
                value: item.value,
                selected: false
              })
            }
          })
          break;
        case 'syncCommentsType':
          context.state[payload.category].syncCommentsType.forEach(item => {
            if (item.id === payload.data.id) {
              data.push({
                id: item.id,
                value: item.value,
                selected: true
              })
            } else {
              data.push({
                id: item.id,
                value: item.value,
                selected: false
              })
            }
          })
          break;
        default:
          throw new Error('vuex: Prefer 內的 updateConfiguration 未處理好');
      }
      /**
       * 這裡多做 data 資料的判斷，因為 fieldFilter / fieldFilterB 內的程式碼大同小異
       * 所以整合成一個 function
       * 但在 vuex 內的 function 只要有用 context.dispatch 都是做非同步的
       * 所以在這邊需要等待 fieldFilterFn return 回來的 data 資料
       */
      let checkPoint = setInterval(() => {
        if (data.length > 0) {
          clearInterval(checkPoint)
          $.ajax({
            type: 'PUT',
            async: true,
            url: updateConfigurationApi,
            data: JSON.stringify(data),
            headers: header,
            contentType: 'application/json',
            success: function(res) {
              console.log(res)
              if (res.code === '200') {
                context.dispatch('getStoreInfo', {
                  storeId: payload.storeId,
                  serverToken: payload.serverToken
                })
              }
            },
            error: function(err) {
              console.log(err.responseJSON)
              alert(err.responseJSON.message)
              setTimeout(() => {
                switchLoading('hide')
              }, 500)
            },
          })
        } else {
          console.log('vuex: Prefer 內的 updateConfiguration 正在等待 data 資料...')
        }
      }, 100)
    },
    /**
    * 特別為同一個 category 內有多個 fieldFilter 做處理
    * @returns data array
    */
    fieldFilterFn(context, payload) {
      let data = []
      console.log(payload.data)
      context.state[payload.category][payload.item].forEach(item => {
        let select = false
        payload.data.forEach(finalRes => {
          if (item.value === finalRes.index) {
            data.push({
              id: item.id,
              value: item.value,
              selected: true
            })
            select = true
          }
        })
        if (!select) {
          data.push({
            id: item.id,
            value: item.value,
            selected: false
          })
        }
      })
      return data
    },
  },
}