import { createStore } from 'vuex'
// 賣場列表
import StoreList from './store/storeList'
// 紀錄目前 participant 的基本資訊 (透過路由更新)
import CurrentParticipant from './store/currentParticipant'
// 紀錄偏好設定
import Prefer from './store/prefer'
// 紀錄同步 FB 社團/粉專 留言的資料
import SyncFbMessages from './store/synchronizeCommunity'

export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    StoreList,
    CurrentParticipant,
    Prefer,
    SyncFbMessages,
  }
})
